<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Edit Item Sales" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">COA *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih COA" :value="data.nama_coa" @click="isModalCoaActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalCoaActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group *</label>
            <vs-select v-model="data.group1" class="w-full">
              <vs-select-item v-for="(item, index) in ['Estate']" :key="index" :value="item" :text="item"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Nama Item *</label>
            <vs-input class="w-full" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Harga *</label>
            <v-money class="w-full" v-model="data.harga"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data COA"
                :active="isModalCoaActive"
                v-on:update:active="isModalCoaActive = $event">
        <Coa :externalFilter="filterCoa" :selectable="true" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ItemSalesRepository from '@/repositories/master/item-sales-repository'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'ItemSalesEdit',
  components: { Coa, ValidationErrors, VMoney },
  props: ['isActive', 'item'],
  watch: {
    isActive (value) {
      if (value === true) this.data = Object.assign({}, this.item)
      // remove default
      this.data.dokumen = null
    }
  },
  computed: {
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  data () {
    return {
      isModalCoaActive: false,
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    onSelectedModalCoa (item) {
      this.data.id_coa = item.id
      this.data.nama_coa = item.nama
      this.isModalCoaActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      ItemSalesRepository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
